(function($) {
    window.appDateTimePicker = function (ele) {
        $(ele).datetimepicker({
            format: 'DD/MM/YYYY HH:mm',
            showClear: true,
            showClose: true,
            icons: {
                time: 'fa fa-clock-o',
                date: 'fa fa-calendar',
                up: 'fa fa-chevron-up',
                down: 'fa fa-chevron-down',
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-calendar-o',
                clear: 'fa fa-trash',
                close: 'fa fa-times'
            }
        });
    }

    window.onConfirmationDialogLoad = function (hrefEle, dialogId) {
        $(dialogId).find('#cancel-button').click(function () {
            $(dialogId).modal('hide');
        });
    }

    window.deleteConfirmationDialog = function (hrefEle, dialogId, messages) {
        if (!messages) {
            messages = {};
        }

        if (messages.loading) {
            messages['loading'] = 'Please wait';
        }

        $(hrefEle).on('click', function (e) {
            e.preventDefault();

            var href = $(this).attr('href');

            $(dialogId).find('.modal-body').html(messages['loading']);

            $(dialogId).modal('show');

            $.ajax({
                type: "GET",
                url: href,
                success : function(responce) {
                    $(dialogId).find('.modal-body').html(responce);
                    window.onConfirmationDialogLoad(hrefEle, dialogId);
                }
            });

            return false;
        });
    }

    window.appImageDropzone = function (ele) {
        /*
        var imageDropzone = new Dropzone('#field-image-dropzone', {
            addRemoveLinks: true,
            url: window.BaseURL + '/image/upload',
            maxFilesize: 2, // MB,
            acceptedFiles: ".png,.jpg,.gif,.bmp,.jpeg,.svg",
            headers: {
                'X-CSRF-Token': '{{ csrf_token() }}',
            }
            
        });

        imageDropzone.on('complete', function (p1, p2) {
            console.log('p1', p1);
            console.log('p2', p2);
        });
    
    $('#field-image-dropzone').addClass('dropzone');
    */
        
    }

    if ($('.confirm-delete-link').length) {
        // ATtach confirmation dialog
        window.deleteConfirmationDialog(
            '.confirm-delete-link',
            '#delete-confirmation-dialog',
            {
                loading: "{{trans('Please wait')}}"
            }
        );
    }
})(jQuery);

window.appCKEEditor = function (eleId, height) {
    if (!window.CKEDITOR) {
        return;
    }

    CKEDITOR.config.allowedContent = true;

    var instanceConfig = {};

    if (height) {
        instanceConfig.height = height;
    }

    instanceConfig.toolbarGroups = [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'document', groups: [ 'document', 'doctools', 'mode' ] },
        { name: 'forms', groups: [ 'forms' ] },
        '/',
        '/',
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] }
    ];

    instanceConfig.removeButtons = 'Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,PasteFromWord,Paste,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,TextColor,BGColor,Maximize,ShowBlocks,About';

    CKEDITOR.replace(eleId, instanceConfig);


    /*
    if (!window.ClassicEditor) {
        return null;
    }

    ClassicEditor
        .create(document.querySelector(eleId), {
            height: 500,
            toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',  '|', 'undo', 'redo' ],
        })
        .catch(error => {
            console.error(error);
        });
    */
}

